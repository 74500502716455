import React from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { Link } from "gatsby-theme-material-ui";
import { graphql } from "gatsby";
import ContentfulRichText, { ContentfulRichTextType } from "src/contentful/contentful-rich-text";
import { Paths } from "src/utilities/constants";
import CustomLayout, { IHeaderProps, headerTypes } from "src/components/layout/custom-layout";
import { navigate } from "@reach/router";
import { PrivacyPageQueryQuery } from "types/graphql-types";
import { useLanguageDataGetter } from "src/hooks/useLanguageData";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  }
}))
interface IProps {
  data: PrivacyPageQueryQuery;
}
type EdgeType = PrivacyPageQueryQuery['allContentfulPrivacyPage']['edges'][0]

const Index = ({ data }: IProps) => {
  const privacyPage = useLanguageDataGetter<EdgeType>(data.allContentfulPrivacyPage);
  const privacyPageData = privacyPage.node;
  const headerProps: IHeaderProps = {
    type: headerTypes.logo
  }

  return (
    <CustomLayout
      footerProps={{}}
      headerProps={headerProps}
    >
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Link onClick={() => { navigate(-1) }}>{privacyPageData.backButtonLabel}</Link>
        </Grid>
        <Grid item xs={12}>
          <ContentfulRichText {...privacyPageData?.content as ContentfulRichTextType} />
        </Grid>
        <Grid item xs={12} >
          <Link onClick={() => { navigate(-1) }}>{privacyPageData.backButtonLabel}</Link>
        </Grid>
      </Grid>
    </CustomLayout>
  );
}

export const query = graphql`
  query PrivacyPageQuery {
    allContentfulPrivacyPage {
      edges {
        node {
          id
          content {
            raw
          }
          node_locale
          backButtonLabel
        }
      }
    }
  }
`

export default Index;